import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
// import Img from "gatsby-image";
import SEO from "../components/seo";
import "../style/basepage.less";

export default function({ data }) {
    return (
        <Layout>
            <SEO
                lang="en"
                title="About"
                description="The Team At Sonoma Bespoke Beverages"
            />
            <div className="container"style={{textAlign: "center"}}>
                <div className="row" style={{padding:20}}>
                    <h1>The Team</h1>
                </div>
                <div className="row flex">
                    <div className="col m6 image s12">
                        <img style={{borderRadius:100}} height={300} src="/images/max_image.jpeg" alt=""/>
                        <div className="row">
                            <h6>Max Franks - Director</h6>
                        </div>
                    </div>
                    <div className="show-mobile" style={{height:40, width:1}}/>
                    <div className="col m6 image s12">
                        <img style={{borderRadius:100}} height={300} src="https://marketplace.donandsons.com/assets/client/Image/3guys.jpg" alt=""/>
                        <div className="row">
                            <h6>Don Sebastiani - Part Owner</h6>
                        </div>
                    </div>
                </div>
                <div style={{height:40, width:1}}/>
                <div className="row flex">
                    <div className="col m6 image s12">
                        <img style={{borderRadius:100}} height={300} src="/images/lee_image.jpeg" alt=""/>
                        <div className="row">
                            <h6>Lee Jorgensen - Interim CTO</h6>
                        </div>
                    </div>
                </div>
                <div style={{height:40, width:1}}/>
                {/*<article className="post">*/}
                {/*    <div className="head text-primary">*/}
                {/*        <h1>{data.markdownRemark.frontmatter.title}</h1>*/}
                {/*    </div>*/}
                {/*    <div className="content row flex">*/}
                {/*        {data.markdownRemark.frontmatter.image && (*/}
                {/*            <div className="center">*/}
                {/*                <div className="img">*/}
                {/*                    <Img*/}
                {/*                        fluid={*/}
                {/*                            data.markdownRemark.frontmatter*/}
                {/*                                .image.childImageSharp.fluid*/}
                {/*                        }*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*        <div*/}
                {/*            className="col s12 m11 l10"*/}
                {/*            dangerouslySetInnerHTML={{*/}
                {/*                __html: data.markdownRemark.html*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</article>*/}
            </div>
        </Layout>
    );
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                description
                image {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            srcSet
                            ...GatsbyImageSharpFluid
                        }
                        id
                    }
                }
            }
        }
    }
`;
